import { config } from '../../helper/get_config';
const menuItem = [
  {
      "title" : "Home",
      "route" : "/",
      "target": ""
  },
  {
      "title" : "Features",
      "route" : "#feature",
      "target": ""
  },
  {
      "title" : "Tutorials",
      "route" : "#tutorials",
      "target": ""
  },
  {
      "title" : "News",
      "route" : "#news",
      "target": ""
  },
  {
      "title" : "Contact",
      "route" : "#contact",
      "target": ""
  },
  {
      "title" : "DownLoad",
      "route" : config.DOWN_APP,
      "target": ""
  }];
  
  export default menuItem;