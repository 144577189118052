import React, { Component } from 'react';
import { Navbar, Nav, Image, Container } from "react-bootstrap";
import menuData from './menu'
import { Animated } from "react-animated-css";
import Link from 'next/link';

class NavBar extends Component {
  state = {
    isTop: true,
    isToggle:false
  };
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 50;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
  }
  onMouseOver = (index) => {
    this.setState({ focus: true, activeIndex: index })
  }
  onMouseOut = () => {
    this.setState({ focus: false })
  }
  render() {
    return (
      <div id="header">
        <Navbar style={{ backgroundColor: this.props.bg === false ? "#0079bd" : "inherit" }} className={ this.state.isTop ? `navbar navbar-fixed-top ${this.state.isToggle ? "scrolled" : ""}` : " navbar navbar-fixed-top scrolled"} fixed="top" expand="lg">
          <Container>
            <Navbar.Brand className='navbar-brand' href="/">
              <Image src={this.state.isTop ? require("../../static/image/salon_manager_logo.svg") : require("../../static/image/logo-color.png")} className='logo-brand ml-sm-5 img-fluid' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{border:0}}>
            <img src={require("../../static/image/navbar-toggler-icon.png")} height="30" width="30" alt="" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbar" className="navbar-collapse collapse">
              <Nav id="top-menu" className="nav navbar-nav navbar-right">
                {
                  menuData.map((item, index) => {
                    return <Link
                      key={index}
                      href={item.route}
                      style={{ marginTop: 10, flexDirection: 'row' }}
                    >
                      <a target={item.target}
                        onMouseOver={() => { this.onMouseOver(index) }}
                        onMouseOut={this.onMouseOut}
                        className={'nav-link'}
                      >
                        {item.title}
                        <Animated animationIn="fadeInLeft" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} isVisible={this.state.focus}>
                          <div style={{ width: "100%", height: 2, backgroundColor: index === this.state.activeIndex ? '#0079be' : 'transparent' }} />
                        </Animated>
                      </a>
                      
                    </Link>
                  })
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
          
          {/* <Navbar.Collapse className="collapse navbar-collapse justify-content-end mr-sm-5 menubar-salon" id="basic-navbar-nav">
            <Nav className="navbar-nav mr-sm-2 navigation-item content-para1">
              {
                this.props.routeName !== "/" ?
                  <Nav className="nav-link">
                    <Link href="/" style={{ marginTop: 10 }}>
                      <a style={{ color: "#fff" }}> Home </a>
                    </Link>
                  </Nav>
                  : null
              }
            </Nav>
          </Navbar.Collapse> */}
        </Navbar>
      </div>
    );
  }
}

export default NavBar;