import React, { Component } from 'react';
import { Container, Modal, Row, Col, Image, } from "react-bootstrap";
import Link from 'next/link';
import facebook from '../../static/image/facebook.svg';
import ins from '../../static/image/instagram.svg';
import twitter from '../../static/image/twitter.svg';
import { config } from '../../helper/get_config';

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const legal = [
      { href: "/", name: "Getting Started", },
      { href: "#feature", name: "Key Features", },
      { href: "#news", name: "Latest News", },
      { href: "#contact", name: "Contact Us", },
    ];
    const legal1 = [
      { href: config.DOWN_APP, name: "Download", },
      { href: "#tutorial", name: "Tutorials", },
      { href: "/legal/help.html", name: "Helps", },
      { href: "/legal/eula.html", name: "Eula", },
    ];
    const legal2 = [
      { href: "/legal/privacy-policy.html", name: "Privacy Policy", },
      { href: "/legal/cookie-policy.html", name: "Cookies Policy", },
      { href: "/legal/terms-of-use.html", name: "Terms Of Use", },
      { href: "/legal/disclaimer.html", name: "Disclaimer", },
    ];

    const renderList = (data) => {
      return data.map((item, index) => (
        <li className="mb-4"><Link href={item.href} key={index}>
          <a className="text-center" style={{ color: '#fff', marginRight: 20, cursor:'pointer' }} >
            {item.name}
          </a>
        </Link></li>
      ))
    };

    return (
      <footer className="footer">
        <Container className="my-5">
          <Row>
            <Col xs={12} md={3}>
              <div>
                <Image src={require("../../static/image/salon_manager_logo.svg")} className='logo-brand img-fluid mb-4' />
              </div>
              <p className="text-white">Salon Manager participates in Square PartnerUp program. Click here for more details.</p>
              <div className="brands pt-5">
                <span className="bd-r"><Image src={twitter} className='logo-brand' /></span>
                <span className="bd-r"><Image src={facebook} className='logo-brand f' /></span>
                <span className="bd-r"><Image src={ins} className='logo-brand' /></span>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <ul className="list-footer">
                {renderList(legal)}
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <ul className="list-footer">
                {renderList(legal1)}
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <ul className="list-footer">
                {renderList(legal2)}
              </ul>
            </Col>
          </Row>
        </Container>
        <Container className="my-3">
          <div>
            <p className="text-center" style={{ color: '#fff' }}>
              Ⓒ { new Date().getFullYear() } — <span>Salon Manager Inc.</span></p>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;